import React, { useEffect, useRef, useState } from 'react'
import statusIcon from '../assets/icons/ic_status.svg'
import downIcon from '../assets/icons/ic_arrowdown.svg'
import upIcon from '../assets/icons/ic_arrowup.svg'
import notifIcon from '../assets/icons/ic_notif.svg'
import settingsIcon from '../assets/icons/ic_settings.svg'
import chatIcon from '../assets/icons/ic_chat.svg'
import IconTint from 'react-icon-tint';
import { db } from '../firebase-config'
import { doc, getDoc } from 'firebase/firestore';
import { useAtom, useSetAtom } from 'jotai'
import { currentRobot, isPageChanged, loggedInUser, robotNickname, selectedPage } from './store/DataStore'

const Header = () => {
    const setSavedPos = useSetAtom(selectedPage);
    const setIsFragmentChanged = useSetAtom(isPageChanged);

    const onSettingsClick = () => {
        setSavedPos(6);
        setIsFragmentChanged(false);
    }

    const onNotifClick = () => {
        console.log("Notif clicked");
    }

    const onChatClick = () => {
        console.log("chat clicked");
    }

    return (
        <div className='flex flex-row w-full'>
            <RobotsDropdown />
            <div className='w-full flex flex-row h-fit justify-end gap-4'>
                <div onClick={onNotifClick} className='p-2 bg-[#3C5BD2] bg-opacity-10 hover:bg-opacity-5 rounded-xl cursor-pointer'>
                    <img src={notifIcon} alt='notifications' className='h-6' />
                </div>
                <div onClick={onSettingsClick} className='p-2 bg-[#3C5BD2] bg-opacity-10 hover:bg-opacity-5 rounded-xl cursor-pointer'>
                    <img src={settingsIcon} alt='settings' className='h-6' />
                </div>
                <div onClick={onChatClick} className='p-2 bg-[#3C5BD2] bg-opacity-10 hover:bg-opacity-5 rounded-xl cursor-pointer'>
                    <img src={chatIcon} alt='chat' className='h-6' />
                </div>
            </div>
        </div>
    )
}

const RobotsDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedRobot, setSelectedRobot] = useAtom(currentRobot)
    const [selectedNickname, setSelectedNickname] = useAtom(robotNickname);
    const toggleDropdown = () => setIsOpen(!isOpen);
    const myComponentRef = useRef(null);
    const [currentUser] = useAtom(loggedInUser);
    const [matchedRobots, setMatchedRobots] = useState([]);

    useEffect(() => {
        const fetchNicknames = async () => {
            try {
                const docRef = doc(db, 'Companies', 'Zeeno_Dev', 'Robots', 'robots');
                const docSnapshot = await getDoc(docRef);

                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    if (data.nicknames && Array.isArray(data.nicknames)) {
                        const allNicknames = data.nicknames;
                        let filteredNicknames;
                
                        if (currentUser.role === 'Tech Support') {
                            filteredNicknames = allNicknames;
                        } else {
                            filteredNicknames = allNicknames.filter(nickname => currentUser.robots.includes(nickname.number));
                        }
                
                        setMatchedRobots(filteredNicknames);
                
                        if (selectedRobot === '' || selectedNickname === '') {
                            setSelectedNickname(filteredNicknames[0].name);
                            setSelectedRobot(filteredNicknames[0].number);
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchNicknames();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    document.addEventListener("mousedown", (e) => {
        if (myComponentRef.current && !myComponentRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    });

    const onRobotSelected = (item) => {
        setIsOpen(false);
        setSelectedRobot(item)
    }

    return (
        <div ref={myComponentRef} className="relative w-fit transition-all delay-200 select-none">
            <div onClick={toggleDropdown} className={`cursor-pointer w-fit min-w-[180px] pl-1 pr-2 py-1 bg-[#3C5BD2] bg-opacity-10  ${isOpen ? 'rounded-bl-none rounded-rb-none rounded-tr-lg rounded-tl-2xl' : 'rounded-r-lg rounded-l-2xl hover:bg-opacity-5'}  focus:outline-none flex flex-row items-center justify-between`}>
                <img src={statusIcon} alt='status' className='h-6' />
                <span className=' truncate text-[#3C5BD2] font-heebo ml-2 font-normal w-full text-ellipsis'>{selectedNickname}</span>
                {!isOpen ? <IconTint src={downIcon} className='h-3' color='#3C5BD2' /> : <IconTint src={upIcon} className='h-3 -mt-1' color='#3C5BD2' />}
            </div>

            {isOpen && (
                <div className="absolute left-92 z-10 w-full py-2 bg-[#e1e6fd] rounded-b-md shadow-xl ">
                    {matchedRobots.map((item, index) => (
                        <span
                            key={index}
                            className="block px-4 py-2 text-sm text-[#3C5BD2] cursor-pointer font-heebo hover:bg-[#b9c7ff44]"
                            onClick={() => {
                                if (item.name == null) {
                                    setSelectedNickname(item.number);
                                } else {
                                    setSelectedNickname(item.name);
                                }
                                onRobotSelected(item.number);
                            }}
                        >{`${item.name == null ? item.number : item.name}`}</span>
                    ))
                    }
                </div>
            )}
        </div>
    );
};

export default Header





