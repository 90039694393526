import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/zeeno_logo.png'
import mail from '../../assets/icons/ic_mail.svg'
import lock from '../../assets/icons/ic_lock.svg'
import welcome from '../../assets/images/welcom_image.png'
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'
import { auth, db } from '../../firebase-config'
import { collection, getDocs, query, serverTimestamp, updateDoc, where } from 'firebase/firestore'
import { useSetAtom } from 'jotai'
import { loggedInUser } from '../store/DataStore'

function Login() {
    const navigate = useNavigate();
    const [savedEmail, setSavedEmail] = useState("");
    const [savedPass, setSavedPass] = useState("");
    const [savedError, setSavedError] = useState("");
    const [isError, setIsError] = useState(false);
    const [users, setUsers] = useState([]);
    const setCurrentUser = useSetAtom(loggedInUser)

    useEffect(() => {
        document.title = "Zeeno | Login";
    }, [])

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const usersCollectionRef = collection(db, 'Companies/Zeeno_Dev/Users');
                const querySnapshot = await getDocs(usersCollectionRef);
                const usersData = [];
                querySnapshot.forEach((doc) => {
                    const userData = doc.data();
                    usersData.push(userData);
                });
                setUsers(usersData);
                console.log("list of users is: " + usersData);

            } catch (error) {
                console.error('Error fetching users: ', error);
            }
        };

        fetchUsers();
        return () => { };
    }, []);

    const refreshLastSeen = async (userEmail) => {
        if (!userEmail) {
          console.error('Invalid user email');
          return;
        }
      
        try {
          const usersCollectionRef = collection(db, 'Companies/Zeeno_Dev/Users');
          const q = query(usersCollectionRef, where("email", "==", userEmail));
          const querySnapshot = await getDocs(q);
      
          if (querySnapshot.empty) {
            console.log('No user found with the given email');
            return;
          }
          const userDoc = querySnapshot.docs[0];
          const userDocRef = userDoc.ref;
    
          await updateDoc(userDocRef, {
            lastlogin: serverTimestamp(),
            isActive: true
          });
      
          console.log('User lastLogin timestamp updated successfully');
        } catch (error) {
          console.error('Error updating user lastLogin: ', error);
        }
      };

      async function onLoginPressed(email, password) {
        // Reset error state at the beginning
        setIsError(false);
        setSavedError("");
    
        let userFound = false;
    
        users.forEach(item => {
            console.log("These are the mails in firebase: " + item.email);
            if (item.email === email) {
                userFound = true;
                console.log("This email is correct and in firebase: " + item.email);
                signInWithEmailAndPassword(auth, email, password)
                    .then((user) => {
                        refreshLastSeen(item.email);
                        setCurrentUser(item);
                        setIsError(false);
                        if(email !== null && password !== null){
                            navigate("/home");
                        }
                        
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        setIsError(true);
                        if (errorCode === "auth/user-not-found") {
                            setSavedError("Please enter a valid user");
                        }
                        else if(errorCode === "auth/wrong-password") {
                            setSavedError("Please enter a valid password");
                        }
                        console.log('error', errorCode);
                    });
                return;
            }
        });
    
        if (!userFound) {
            setIsError(true);
            setSavedError("Please enter a valid user");
        }
    }

    const onForgotPasswordPressed = async (email) => {
        if (!email) {
            setSavedError("Please enter your email address");
            setIsError(true);
            return;
        }

        try {
            await sendPasswordResetEmail(auth, email);
            setSavedError("Password reset email sent successfully");
            setIsError(false);
        } catch (error) {
            setSavedError("Error sending password reset email");
            setIsError(true);
            console.error('Error sending password reset email: ', error);
        }
    }

    return (
        <div className='flex flex-row 2xl:p-8 lg:p-6 items-center'>
            <div className='bg-[#3C5BD2] 2xl:w-[480px] lg:w-[380px] 2xl:h-[890px] lg:h-[550px] rounded-3xl p-10 flex items-center flex-col'>
                <img src={logo} alt="logo" className='2xl:h-14 lg:h-11' />
                <h1 className='2xl:mt-28 lg:mt-10 text-white font-heebo 2xl:text-3xl lg:text-xl font-medium'>Log In</h1>
                <span className='font-heebo font-light 2xl:text-xl lg:text-md text-white 2xl:mt-1'>Enter your email & password</span>
                <div className='flex flex-row 2xl:py-2 lg:py-1 px-4 items-center w-full 2xl:mt-8 lg:mt-6 rounded-xl bg-[#9EADE9]'>
                    <img src={mail} alt="mail" className='h-6' />
                    <input onChange={(e) => setSavedEmail(e.target.value)} placeholder='Email' className='font-heebo font-light placeholder:text-[#152FF5] 2xl:text-xl lg:text-lg bg-transparent ml-4 w-full text-[#152FF5] outline-none' />
                </div>
                <div className='flex flex-row 2xl:py-2 lg:py-1 px-4 items-center w-full rounded-xl bg-[#9EADE9] 2xl:mt-9 lg:mt-6'>
                    <img src={lock} alt="lock" className='h-6' />
                    <input onChange={(e) => setSavedPass(e.target.value)} type='password' placeholder='Password' className='font-heebo font-light placeholder:text-[#152FF5] 2xl:text-xl lg:text-lg bg-transparent ml-4 w-full text-[#152FF5] outline-none' />
                </div>
                <span className='text-lg w-full text-red-200 font-heebo font-light mt-3'>{savedError}</span>
                <span className='2xl:text-xl lg:text-md font-light font-heebo text-white w-full text-end underline underline-offset-8 decoration-1 mt-6 cursor-pointer' onClick={() => onForgotPasswordPressed(savedEmail)}>Forgot Password</span>
                <span className='bg-[#152FF5] hover:bg-white hover:text-[#152FF5] p-3 w-full text-center text-white 2xl:text-xl lg:text-lg font-heebo font-regular rounded-2xl 2xl:mt-20 lg:mt-20 cursor-pointer transition ease-in-out delay-75' onClick={() => onLoginPressed(savedEmail, savedPass)}>Log In</span>
                <span className={`text-white font-heebo font-light 2xl:text-xl lg:text-md ${isError ? '2xl:mt-40 lg:mt-1' : '2xl:mt-52 lg:mt-4'}  cursor-pointer`}>Need Help? Contact Us</span>
            </div>
            <img src={welcome} alt="welcome" className=' 2xl:h-[800px] lg:h-[500px] 2xl:ml-36 lg:ml-20' />
        </div>
    )
}

export default Login