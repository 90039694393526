import React, { useState } from 'react';
import Header from '../Header';
import emailjs from 'emailjs-com';

const SupportFragment = () => {
  const [fullname, setFullname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');

  const handleFullnameChange = (e) => {
    const newName = e.target.value;
    const nameRegex = /^[A-Za-z]+(?:\s[A-Za-z]*)*$/;
    if (newName === '' || nameRegex.test(newName)) {
      setFullname(newName);
    }
  };

  const handlePhoneNumberChange = (e) => {
    const newPhoneNumber = e.target.value.replace(/[^0-9+\- ]/g, '');
    setPhoneNumber(newPhoneNumber);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = () => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email);
  };

  const onSendClick = () => {
    if (fullname !== '' && phoneNumber !== '' && description !== '' && email !== '' && validateEmail()) {
      const templateParams = {
        fullname,
        phoneNumber,
        description,
        email,
      };
      console.log('Sending email with params:', templateParams);
      
      emailjs.send('service_9cj3af3', 'template_99iqfi3', templateParams, 'QcZ9ORMqNrskXFdvl')
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          alert('Your message was sent to Zeeno Support');
        }, (err) => {
          console.error('FAILED...', err);
        });
    }
  };

  return (
    <div className='bg-[#F0F2FF] px-10 py-6 lg:h-[600px] 2xl:h-full overflow-auto flex flex-col'>
      <Header />
      <div className='flex flex-col w-fit 2xl:mt-14 lg:mt-8'>
        <h1 className='font-heebo text-xl  text-[#273a84]'>Support</h1>
        <p className='font-heebo text-lg font-light w-[850px] mt-1'>We constantly trying to improve and upscale our efficiency.
          If you have any suggestions/issues, please let us know or try to check our User Manual.</p>
      </div>
      <div className='flex flex-col w-fit'>
        <div className='flex flex-col mt-5'>
          <span className='font-heebo text-lg font-light'>Email</span>
          <input className='bg-[#3C5BD2] bg-opacity-10 px-2 py-4 h-7 mt-1 rounded-md w-[350px] h-[50px] outline-none font-heebo font-light' value={email} onChange={handleEmailChange} />
        </div>
        <div className='mt-7 flex flex-row'>
          <div className='flex flex-col'>
            <span className='font-heebo text-lg font-light'>Full Name</span>
            <input className='bg-[#3C5BD2] bg-opacity-10 px-2 py-4 h-7 mt-1 rounded-md w-[350px] h-[50px] outline-none font-heebo font-light' value={fullname} onChange={handleFullnameChange} />
          </div>
          <div className='flex flex-col ml-5'>
            <span className='font-heebo text-lg font-light'>Phone Number</span>
            <input className='bg-[#3C5BD2] bg-opacity-10 px-2 py-4 h-7 mt-1 rounded-md w-[350px] h-[50px] outline-none font-heebo font-light' value={phoneNumber} onChange={handlePhoneNumberChange} />
          </div>
        </div>
        <span className='font-heebo text-lg font-light mt-6'>Description</span>
        <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Type description here...' className='h-44 bg-[#3C5BD2] bg-opacity-10 p-4 rounded-lg outline-none resize-none font-heebo w-full font-light mt-1'></textarea>
      </div>
      <span onClick={onSendClick} className={`mt-6 px-8 py-2 bg-[#3C5BD2] w-fit font-heebo text-white rounded-xl text-lg  ${phoneNumber !== '' && fullname !== '' && description !== '' && email !== '' && validateEmail() ? 'opacity-100 hover:bg-[#3d64ff] cursor-pointer' : 'opacity-25'}`}>Send Message</span>
    </div>
  );
};

export default SupportFragment;