import React, { useEffect, useState } from 'react';
import IconTint from 'react-icon-tint';
import checkIcon from '../../assets/icons/ic_check.svg'
import statusIcon from '../../assets/icons/ic_statuspatient.svg'
import maskIcon from '../../assets/icons/ic_mask.svg'
import { collection, getDocs, query, where, doc, getDoc, updateDoc, onSnapshot, orderBy } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { useAtom, useSetAtom } from 'jotai';
import { checkedPatientIds, currentRobot } from '../store/DataStore';
import axios from 'axios';
import qs from 'qs';

const CheckinPopup = ({ isOpen, children, onClose, onSend }) => {
    const [show, setShow] = useState(isOpen);
    const [patientsList, setPatientsList] = useState([]);
    const [savedRobot] = useAtom(currentRobot);

    const confirmAppointmentGPS = async (appointmentId) => {
        const docPath = `Companies/Zeeno_Dev/Dolphin/api`;
        const docRef = doc(db, docPath);
        console.log("Saved robot: ", savedRobot);
        const practiceIdPath = `Companies/Zeeno_Dev/Dolphin/${savedRobot}`;
        const practiceIdRef = doc(db, practiceIdPath);
        // Function to get the practiceid field
        const getPracticeId = async () => {
            try {
                const docSnap = await getDoc(practiceIdRef);
                if (docSnap.exists()) {
                    const practiceId = docSnap.data().practiceid;
                    console.log('Practice ID:', practiceId);
                    return practiceId;
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error getting document:', error);
            }
        };

        const updateAppointment = async (bearerKey) => {
            const practiceId = await getPracticeId();
            let data = qs.stringify({});
            const config = {
                method: 'put',
                url: `https://dolphin-ocean-api-1.servicebus.windows.net/${practiceId}/dapi/AppointmentGpsStatus?appointmentId=${appointmentId}&gpsStatusId=c5782f1a-0557-4eea-8143-ba5a23feb59e`,
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${bearerKey}`,
                },
                data: data
            };

            try {
                const response = await axios.request(config);
                console.log("Response data:", JSON.stringify(response.data));
            } catch (error) {
                console.error("Error:", error);
            }
        };

        onSnapshot(docRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                console.log('Bearer updated ' + data.key);
                updateAppointment(data.key);
            } else {
                console.log("Document does not exist!");
            }
        }, (error) => {
            console.error("Error listening to document: ", error);
        });

        const appointmentsCollectionPath = `Companies/Zeeno_Dev/Dolphin/${savedRobot}/Appointments`;
        const appointmentsCollectionRef = collection(db, appointmentsCollectionPath);
        const q = query(appointmentsCollectionRef, where("Id", "==", appointmentId));

        try {
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const docToUpdate = querySnapshot.docs[0];
                await updateDoc(docToUpdate.ref, {
                    Confirmed: true,
                });
                console.log(`Appointment with ID ${appointmentId} updated to confirmed status: true`);
                handleClose();
            } else {
                console.log(`No appointment found with ID ${appointmentId}`);
            }
        } catch (error) {
            console.error('Error updating confirmed status: ', error);
        }
    };

    useEffect(() => {
        if (savedRobot !== '') {
            console.log('Robot SN:', savedRobot);
            fetchPatients(savedRobot);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedRobot]);

    const fetchPatients = async (savedRobot) => {
        const q = query(collection(db, "Companies", "Zeeno_Dev", "Dolphin", savedRobot, "Appointments"), where("Confirmed", "==", false),orderBy("ScheduledTimeBack"));
        const querySnapshot = await getDocs(q);
        const patients = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log("Patients are " + patients.toString())
        setPatientsList(patients);
    };

    useEffect(() => {
        if (isOpen) {
            setShow(true);
        } else {
            setShow(false);
            setTimeout(onClose, 200);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, onClose]);

    const handleClose = () => {
        setShow(false);
        setTimeout(onClose, 200);
    };

    const handleSend = () => {
        onSend();
        setShow(false);
        setTimeout(onClose, 200);
    }

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full transition-opacity duration-200 ${show ? 'opacity-100' : 'opacity-0'}`}
            onClick={handleClose}
            style={{ display: show || isOpen ? 'block' : 'none' }}
        >
            <div
                className="relative top-20 mx-auto p-4 border w-fit shadow-lg rounded-md bg-white ml"
                onClick={e => e.stopPropagation()}
            >
                <div className="flex justify-end">
                    <button onClick={handleClose} className="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-0.5 text-sm ml-auto inline-flex items-center">
                        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="-mt-4 flex flex-col items-center">
                    {children}
                </div>

                <div className='mt-4 max-h-60 overflow-y-auto'>
                    {patientsList.map((item, index) => (
                        <div key={index}>
                            <PatientModel name={item.FirstName} time={item.ScheduledTime} chair={item.Chair} doctor={item.LocationName} id={item.Id} confirmAppointmentGPS={confirmAppointmentGPS} />
                        </div>
                    ))}
                </div>

                <div className='w-full flex justify-center mt-10'>
                    {(true) ?
                        <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center cursor-pointer' onClick={handleSend}>
                            <span className='text-white font-heebo'>Done</span>
                            <IconTint src={checkIcon} className='h-5 ml-2' color='#FFFFFF' />
                        </div>
                        :
                        <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center opacity-50'>
                            <span className='text-white font-heebo'>Done</span>
                            <IconTint src={checkIcon} className='h-5 ml-2' color='#FFFFFF' />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

const PatientModel = ({ name, time, chair, doctor, id, confirmAppointmentGPS }) => {
    const [checkInBtn, setCheckInBtn] = useState("Check In");
    const setCheckedIds = useSetAtom(checkedPatientIds);

    const onCheckPressed = () => {
        if (checkInBtn === "Checked") {
            setCheckInBtn("Check In");
        } else {
            setCheckInBtn("Checked");
            console.log("The id added is " + id);
            setCheckedIds((oldCheckedIds) => [...oldCheckedIds, id]);
            confirmAppointmentGPS(id);
        }
    }

    return (
        <div className='flex flex-row h-14 bg-[#8290F8] px-2 bg-opacity-10 rounded-l-[100px] rounded-r-3xl items-center mb-2.5 border-l-[#8290F8] border-l-[5px]'>
            <img alt='patient_status' src={statusIcon} className='h-10' />
            <div className='flex flex-col w-32 ml-2'>
                <span className='font-heebo text-md'>{name}</span>
                <span className='font-heebo font-light text-sm'>Patient</span>
            </div>
            <div className='flex flex-col w-36'>
                <span className='font-heebo text-md'>{time}</span>
                <span className='font-heebo font-light text-sm'>Scheduled Time</span>
            </div>
            <div className='flex flex-col w-20'>
                <span className='font-heebo text-md'>{chair}</span>
                <span className='font-heebo font-light text-sm'>Chair</span>
            </div>
            <img alt='doctor' src={maskIcon} className='h-7 -mt-4' />
            <div className='flex flex-col w-32 ml-3'>
                <span className='font-heebo text-md'>{doctor}</span>
                <span className='font-heebo font-light text-sm'>Doctor</span>
            </div>
            <div className={`flex w-fit bg-[#152FF5] bg-opacity-80 hover:bg-opacity-100 py-1 px-3 rounded-lg items-center cursor-pointer ${checkInBtn === "Checked" && 'bg-opacity-100'}`} onClick={() => { onCheckPressed() }}>
                <span className='text-white font-heebo font-light'>{checkInBtn}</span>
            </div>
        </div>
    )
}

export default CheckinPopup;