import React, { useEffect, useState } from 'react'
import Header from '../Header'
import addimg from '../../assets/icons/ic_add.svg'
import vidempty from '../../assets/images/zeeno_logo.png'
import optionsIcon from '../../assets/icons/ic_menu.svg'
import { collection, doc, getDocs, query, updateDoc, where, addDoc, Timestamp } from 'firebase/firestore'
import { db } from '../../firebase-config'
import { useAtom } from 'jotai'
import { currentRobot , robotNickname} from '../store/DataStore'
import AddVideoPopup from '../popups/AddVideoPopup'

const VideosFragment = ({userName}) => {
  const [videos, setVideos] = useState([]);
  const [savedRobot] = useAtom(currentRobot);
  // const [currBatteryAmount] = useState(0);
  const [savedNickname] = useAtom(robotNickname);
  const [showAddView, setShowAddView] = useState(false);

  const startVideo = async (video_name, video_url, video_length) => {
    const logPath = `Companies/Zeeno_Dev/Actions/${savedRobot}/Logs`;
      const collectionRef = collection(db, logPath);      
      // Create a new document in the collection and get its ID
      const newDocRef = await addDoc(collectionRef, {});         
      // Create a reference to the document we just created
      const docRef = doc(db, `${logPath}/${newDocRef.id}`);          
      // Create the log entry object with the dynamic areaSelected
      const action = {
        by: userName,
        description: "Show Video",
        status: "upcoming",
        timestamp: Timestamp.now(), // Use Timestamp.now() for the current time
        content: [
          "video",
          video_url,
          video_name,
          video_length
          ],
        robot_name: savedNickname,
        // network_status: "Online",
        // battery_amount: currBatteryAmount.toString(),
        wifi_status: "Online", 
        // next_tasks: "3", 
        is_approved: false,
        is_running: false
      };
      await updateDoc(docRef, action);
      alert("Video will be played on the robot shortly");
  }

  // const startVideo = async (index) => {
  //   const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/videolibrary`;
  //   const docRef = doc(db, docPath);
  //   try {
  //     await updateDoc(docRef, {
  //       currvideo_index: index,
  //       toggle_startvid: true
  //     });
  //   } catch (error) {
  //     console.error('error updated location: ', error);
  //   }
  // }

  const getVideoList = async () => {
    try {
      const videosRef = query(
        collection(db, "Companies", "Zeeno_Dev", "Videos"),
        where("robots", "array-contains", savedRobot)
        // orderBy("order") // Uncomment if ordering is needed
      );

      const snapshot = await getDocs(videosRef);
      const videosData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setVideos(videosData);
    } catch (error) {
      console.error("Error fetching videos: ", error);
    }
  };

  useEffect(() => {
    getVideoList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedRobot]);

  return (
    <div className='bg-[#F0F2FF] px-10 py-6 lg:h-[600px] 2xl:h-full overflow-auto'>
      <Header />
      <AddVideoPopup isOpen={showAddView} onClose={() => setShowAddView(false)} onAdd={() => getVideoList()}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Add New Video</h2>
        <p className="text-md text-gray-800 font-heebo font-light mt-1">Drag or Attach your file to the screen</p>
      </AddVideoPopup>
      <div className='flex flex-row w-fit 2xl:mt-14 lg:mt-8 items-center'>
        <h1 className='font-heebo text-xl  text-[#273a84]'>Video & Library</h1>
        <img src={addimg} alt='add' className='h-6 bg-[#3C5BD2] p-1.5 rounded-full ml-4 cursor-pointer' onClick={() => setShowAddView(true)} />
      </div>
      <div className="grid 2xl:grid-cols-4 lg:grid-cols-3 gap-5 w-fit mt-5">
        {videos.map((video, index) => (
          <Video
            key={index}
            thumbnail={video.video_thumbnail}
            name={video.video_name}
            duration={video.video_length}
            startVideo={() => startVideo(video.video_name, video.video_url, video.video_length)}
          />
        ))}
      </div>
    </div>
  )
}

const Video = ({ thumbnail, name, duration, startVideo }) => {
  return (
    <div className="flex flex-col 2xl:w-80 lg:w-72 cursor-pointer">
      <div className='2xl:h-44 lg:h-32 flex flex-col items-center justify-center bg-[#2C2C2C] bg-opacity-10 w-full rounded-t-xl' onClick={startVideo}>
        <img src={(thumbnail === "" || thumbnail === null ) ? vidempty : thumbnail} alt={name} className={`${thumbnail!=="" ? 'w-full h-full rounded-t-xl' : 'p-16'}`} />
      </div>
      <div className='flex flex-row bg-white rounded-b-xl'>
        <div className="py-2 px-3 w-[90%]">
          <h3 className="2xl:text-lg lg:text-md font-medium font-heebo">{name}</h3>
          <p className="text-sm font-heebo font-light opacity-80">{duration}</p>
        </div>
        <img src={optionsIcon} alt='menu' className='hover:bg-slate-100 p-1 hover:bg-opacity-40' />
      </div>
    </div>
  );
};


export default VideosFragment